import { Component, OnInit } from '@angular/core';
import { VersionInfoService } from './version-info.service';
import uiVersionJson from 'src/version.json';

@Component({
  selector: 'sai-version-info',
  templateUrl: './version-info.component.html',
})
export class VersionInfoComponent implements OnInit {
  versionInfo = {
    uiVersion: uiVersionJson.version,
    uiUpdateDate: uiVersionJson.updateDate,
    version: '',
    updateDate: '',
  };

  constructor(private versionInfoService: VersionInfoService) {}

  ngOnInit() {
    this.versionInfoService.getVersionInfo().subscribe((response) => {
      this.versionInfo.version = response.version;
      this.versionInfo.updateDate = response.updateDate;
    });
  }
}
