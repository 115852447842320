import { Environment } from '@abp/ng.core';

const baseUrl = window.location.origin;

export const environment = {
  production: true,
  hmr: false,
  application: {
    name: 'SestekAI',
    baseUrl,
  },
  oAuthConfig: {
    issuer: '/sestekai-auth',
    clientId: 'SestekAI_App',
    dummyClientSecret: '1q2w3e*',
    responseType: 'code',
    scope: 'offline_access SestekAI',
    skipIssuerCheck: true,
    strictDiscoveryDocumentValidation: false,
    showDebugInformation: true,
    requireHttps: false,
    redirectUri: baseUrl,
  },
  apis: {
    default: {
      url: '/sestekai-api',
      rootNamespace: 'SestekAI',
    },
  },
} as Environment;
