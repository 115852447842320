<div class="row">
  <div *ngIf="versionInfo.version && versionInfo.updateDate" class="col-md-12 float-left">
    {{ '::CurrentSTAIVersion' | abpLocalization }} : {{ versionInfo.version }} ({{
      versionInfo.updateDate
    }})
  </div>
  <div class="col-md-12 float-left">
    {{ '::CurrentUIVersion' | abpLocalization }} : {{ versionInfo.uiVersion }} ({{
      versionInfo.uiUpdateDate
    }})
  </div>
</div>
