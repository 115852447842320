import { AuthService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { ReplaceableComponentsService } from '@abp/ng.core';
import { eThemeBasicComponents } from '@abp/ng.theme.basic';
import { NavItemsComponent } from './shared/nav-items/nav-items.component';

@Component({
  selector: 'sai-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
  providers: [NgbTooltipConfig],
})
export class AppComponent implements OnInit {
  constructor(
    private oAuthService: OAuthService,
    private authService: AuthService,
    private ngbTooltipConfig: NgbTooltipConfig,
    private replaceableComponents: ReplaceableComponentsService
  ) {
    this.ngbTooltipConfig.openDelay = 400;
  }

  get hasLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }
  ngOnInit() {
    this.replaceableComponents.add({
      component: NavItemsComponent,
      key: eThemeBasicComponents.NavItems,
    });

    if (!this.hasLoggedIn) {
      this.authService.navigateToLogin();
    }
    if (!localStorage.getItem('delimiter')) {
      localStorage.setItem('delimiter', '0');
    }
  }
}
